<template>
  <div :style="new_style_variables" :class="getSlug()">
    <Header
      v-if="loadfinal"
      :logo_link="`/index.html`"
      :home_link="`/index.html`"
      :lang="lang.toUpperCase()"
      :show_home="true"
      :show_faqs="false"
      :show_manage="false"
      :show_languages="true"
      :show_basket="false"
      :show_login="false"
      :show_signup="false"
      :brandSlug="brand_slug"
      :basket_number="0"
      :logoutText="getrans('Logout')"
    ></Header>
    <div class="page-bg" :class="brand_slug">
      <div class="top-panel">
        <div class="top-panel-inner">
          <h1 v-if="brand_slug === 'SV1'">{{ getrans("Manage Bookings") }}</h1>
          <h1 v-else>{{ getrans("Login") }}</h1>
        </div>
      </div>

      <!-- test push -->

      <div class="page-container">
        <div class="page-left">
          <div class="form-panel" style="text-align: left">
            <div class="form-inner" v-if="userexists">
              <div class="title-area">
                <h3>
                  {{
                    getrans(
                      "The email id entered does not exist in our system."
                    )
                  }}
                </h3>
              </div>
            </div>

            <div class="form-inner" v-if="loginistrue">
              <div class="title-area">
                <h3>{{ getrans("Please enter your email to continue") }}</h3>
              </div>

              <div class="form-inputs">
                <div
                  class="block-wrap block-wrap-has-question-icon"
                  style="width: 50%"
                >
                  <div
                    class="block"
                    :class="`${emailInvalid ? 'error' : ''} ${
                      emailValid ? 'tick' : ''
                    } ${emailid ? 'hasValue' : ''}`"
                  >
                    <input
                      name="emailid"
                      autofocus="autofocus"
                      type="text"
                      id="emailid"
                      v-model.trim="emailid"
                      data-lpignore="true"
                      @keyup.enter="proceedtocheckuser"
                    />
                    <label for="emailid">
                      <template v-if="brand_slug === 'SV1'">
                        {{ getrans("Email address") }}
                      </template>
                      <template v-else>
                        {{ getrans("Enter your email address") }}
                      </template>
                      <span class="form-mandatory">*</span>
                    </label>
                  </div>
                </div>

                <div class="block-wrap" style="width: 100%">
                  <div class="block-wrap-btn">
                    <button
                      type="button"
                      @mousedown="proceedtocheckuser"
                      @keyup.enter="proceedtocheckuser"
                      :disabled="submitStatusCheckEmail === 'PENDING'"
                      class="proceed-btn"
                    >
                      <i
                        v-if="submitStatusCheckEmail === 'PENDING'"
                        class="fas fa-circle-notch fa-spin"
                      ></i>
                      {{ getrans("Proceed") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-inner" v-if="passistrue">
              <div class="title-area" v-if="display_msg">
                <h3 class="page-content__form-title">
                  {{ getrans(display_msg) }}
                </h3>
              </div>
              <div v-else class="title-area">
                <h3 v-if="brand_slug === 'NWI'">
                  {{
                    getrans(
                      "You have been sent a 6 digit One Time Passcode to"
                    )
                  }}&nbsp;{{ emailid }}
                </h3>
                <h3 v-else-if="brand_slug === 'YHM'">
                  {{
                    getrans(
                      "You have been sent a one-time passcode (OTP) to your registered email."
                    )
                  }}
                </h3>
                <h3 v-else-if="brand_slug === 'SV1'">
                  {{
                    getrans(
                      "We have sent a one-time passcode to"
                    )
                  }}&nbsp;{{ emailid }}
                </h3>
                <h3 v-else>
                  {{
                    getrans(
                      "You have been sent a 6 digit One Time Passcode to"
                    )
                  }}&nbsp;{{ emailid }}
                </h3>
              </div>

              <div class="form-inputs" style="padding: 0.5rem">
                <div class="block-wrap" style="width: 60%">
                  <OTPInput
                    message=""
                    v-on:opt_change="opt_change"
                    :otpLength="6"
                  />

                  <p>
                    <template v-if="brand_slug === 'SV1'">
                      {{getrans('Please leave this tab open while retrieving the access code.')}}
                    </template>
                    <template v-else>
                      {{getrans('Please do not access your email in this tab, please leave this tab open as you check your email to retrieve the One Time Passcode.')}}
                    </template>
                  </p>
                  <p>{{getrans("Didn&#39;t receive code?")}} <a href="/">{{getrans('try again')}}</a></p>

                  <div
                    style="display: none"
                    class="block"
                    :class="`${passwordWrong ? 'error' : ''} ${
                      passwordCorrect ? 'tick' : ''
                    } ${password ? 'hasValue' : ''}`"
                  >
                    <input
                      name="otpotp"
                      autofocus="false"
                      type="password"
                      v-model.trim="password"
                      data-lpignore="true"
                      @keyup.enter="proceedtologinuser"
                      @input="ValidatePassword"
                    />
                    <label for="password" v-if="brand_slug === 'YHM'">
                      {{
                        getrans(
                          "Please enter your OTP below to access your account"
                        )
                      }}
                      <span class="form-mandatory">*</span>
                    </label>
                    <label for="password" v-else>
                      {{ getrans("Enter your OTP") }}
                      <span class="form-mandatory">*</span>
                    </label>
                  </div>
                </div>
                <div class="block-wrap-btns">
                  <button
                    type="button"
                    @mousedown="proceedtologinuser"
                    @keyup.enter="proceedtologinuser"
                    :disabled="
                      !password || submitStatusCheckPassword === 'PENDING'
                    "
                    class="proceed-btn login-btn"
                  >
                    <i
                      v-if="submitStatusCheckPassword === 'PENDING'"
                      class="fas fa-circle-notch fa-spin"
                    ></i>&nbsp;
                    <template v-if="brand_slug === 'SV1'">
                      {{ getrans("Log In") }}
                    </template>
                    <template v-else>
                      {{ getrans("Login") }}
                    </template>
                  </button>

                  <button
                    type="button"
                    @click="cancellogin"
                    class="proceed-btn cancel-btn"
                  >
                    {{ getrans("Cancel") }}
                  </button>
                </div>
              </div>
            </div>

            <div
              class="form-inner"
              v-if="callemailsms && !passistrue && !loginistrue"
            >
              <div class="form-inputs">
                <div class="title-area">
                  <h3>
                    {{
                      getrans(
                        "How do you like to receive the 6 digit One Time Passcode to proceed?"
                      )
                    }}
                  </h3>
                </div>

                <div class="block-wrap-btns" style="width: 100%">
                  <button
                    type="button"
                    @click="sendcodeoption('email')"
                    class="proceed-btn login-btn"
                  >
                    <i
                      v-if="submitSendcodeoption_email"
                      class="fas fa-circle-notch fa-spin"
                    ></i>
                    {{ getrans("Email") }}
                  </button>
                  <button
                    type="button"
                    @click="sendcodeoption('sms')"
                    class="proceed-btn sms-btn"
                    style="margin-left: 1rem"
                  >
                    <i
                      v-if="submitSendcodeoption_sms"
                      class="fas fa-circle-notch fa-spin"
                    ></i>
                    {{ getrans("SMS") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="page-right"></div>
        </div>
      </div>
    </div>
    <Footer
      :footer_links="footer_links"
      :footer_columns="footer_columns"
      :lang="lang.toUpperCase()"
      :translations="translations"
      :brandSlug="brand_slug"
    ></Footer>
    <Cookies :brandSlug="brand_slug" />
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import Cookies from "../../components/Cookies.vue";
import OTPInput from "../../components/OTPInput.vue";
import axios from "axios";
import Swal from "sweetalert2";
import genkeys from "rezauthlib";
import md5 from "js-md5";
const localforage = require("localforage");
import { mapActions, mapGetters } from "vuex";
let store = localforage.createInstance({
  name: "tranlations",
});
import("primevue/resources/themes/saga-blue/theme.css");
import("primevue/resources/primevue.min.css");
import("primeicons/primeicons.css");
const { EncryptStorage } = require("encrypt-storage");
const encryptStorage = new EncryptStorage(process.env.VUE_APP_CHL_ENC, {
  storageType: "sessionStorage",
});
const crypto = require("crypto");	
import { set_style_vars } from "@/modules/set_style_vars";
export default {
  name: "App",
  components: {
    Header,
    Footer,
    Cookies,
    OTPInput,
  },
  data() {
    return {
      brand_slug: "",
      translations: "",
      lang: "",
      branding_data: {},
      emailid: "",
      emailInvalid: false,
      callemailsms: false,
      loadfinal: false,
      passwordless: false,
      emailValid: false,
      password: "",
      passwordWrong: false,
      passwordCorrect: false,
      userexists: false,
      loginistrue: true,
      passistrue: false,
      display_msg: "",
      submitStatusCheckEmail: "OK",
      submitStatusCheckPassword: "OK",
      domain: null,
      brand_product_styles: {},
      header_menu: [],
      footer_links: [],
      footer_columns: "1 Row",
      submitSendcodeoption_email: false,
      submitSendcodeoption_sms: false,
    };
  },
  computed: {
    new_style_variables() {
      return set_style_vars(this.brand_product_styles);
    },
    set_style_variables: function () {
      return {
        "--brand_color_1": this.brand_color_1 || "#ffffff",
        "--brand_color_2": this.brand_color_2 || "#1a2a40",
        "--brand_color_3": this.brand_color_3 || "#dea602",
        "--tab_active_text_color": this.tab_active_text_color || "#1a2a40",
        "--tab_active_text_size": this.tab_active_text_size || "#1a2a40",
        "--tab_in_active_text_color": this.tab_inactive_text_color || "1rem",
        "--tab_in_active_text_size": this.tab_inactive_text_size || "1rem",
        "--border_radius": this.border_radius || "0",
      };
    },
  },
  methods: {
    ...mapActions(["setSlug", "setBranding"]),
    ...mapGetters(["getSlug", "getCurrency", "getBranding"]),
    setStyles(obj) {
      /* eslint-disable */
      obj.forEach((item) => {
        for (const [classname, style] of Object.entries(item)) {
          this.brand_product_styles[classname] = style;
        }
      });
      /* eslint-enable */
    },
    sendcodeoption(val) {
      let callurl = "";
      let secotp = "";
      if (val == "email") {
        callurl =
          "https://api-london.rezcomm.com/sso/frontend/v2/email/login/otp/send";
        secotp = genkeys(
          encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETID_LDN,
          encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETKEY_LDN
        );
      }
      if (val == "sms") {
        callurl =
          "https://api-london.rezcomm.com/sso/frontend/mobile/login/otp/send";
        secotp = genkeys(
          encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETID_LDN_M,
          encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETKEY_LDN_M
        );
      }
      let datatogenotp = {
        brand_id: this.brand_slug,
        email_id: this.emailid,
        uid: this.getLocalData("uuid"),
        language: this.lang.toUpperCase(),
      };

      let b1o = secotp.b1s;
      let v2o = secotp.v2s;
      let a1o = secotp.a1;
      let v1o = secotp.v1;
      let c2o = secotp.c2s;
      let uidgeno = secotp.uidgen;
      axios
        .post(callurl, datatogenotp, {
          headers: {
            b1: b1o,
            v2: v2o,
            c2s: c2o,
            a1: a1o,
            v1: v1o,
            c1: uidgeno,
          },
        })
        .then((response) => {
          console.log(response);
          this.submitSendcodeoption_email = false;
          this.submitSendcodeoption_sms = false;
          let datasent = JSON.parse(response.request.response);
          this.loginistrue = false;
          this.passistrue = true;
          this.password = "";
          if (datasent && datasent.msg && datasent.msg.display_msg) {
            this.display_msg = datasent.msg.display_msg;
            console.log("this.display_msg", this.display_msg);
          }
        });
    },
    setMenu(data) {
      data.brand_menu_mmb.forEach((menu) => {
        if (menu.label.toLowerCase() === "home") {
          this.home_link = menu.url;
        }
        menu.loggedin = true;
        menu.loggedout = true;
      });
      const top_menu = data.brand_menu_mmb.filter(
        (menu) => menu.position === "T"
      );
      this.createHeaderLinks(top_menu);
      const footer_menu = data.brand_menu_mmb.filter(
        (menu) => menu.position === "B"
      );
      this.createFooterLinks(footer_menu);
    },
    createHeaderLinks(data) {
      const header_menu = [];
      if (data.length > 0) {
        data.forEach((menu) => {
          if (menu.label.toLowerCase() === "basket") {
            this.show_basket = true;
          } else if (menu.label.toLowerCase() !== "home") {
            header_menu.push(menu);
          }
        });
      }
      this.header_menu = header_menu;
    },
    createFooterLinks(data) {
      this.footer_columns = data[0].mcolumn;
      this.footer_links = data.reduce((a, r) => {
        if (!a[r.mgroup]) a[r.mgroup] = { group: r.mgroup, data: [] };
        a[r.mgroup].data.push(r);
        return a;
      }, {});
    },
    opt_change(value) {
      //console.log(value);
      this.password = value;
    },
    validatePassword() {
      this.passwordWrong = this.password.length !== 6 || isNaN(this.password);
      this.passwordCorrect =
        this.password.length > 0 &&
        this.password.length === 6 &&
        !isNaN(this.password);
    },
    validateEmail(mail) {
      if (
        // /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        //   mail
        // )
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
          mail
        )
      ) {
        return true;
      }
      return false;
    },
    getrans(string) {
      var s = md5(string);
      var ss = this.brand_slug + "-" + this.lang.toUpperCase() + "-" + s;
      //console.log(ss);
      return this.translations[ss] || string;
    },
    getSessionData(name) {
      return JSON.parse(sessionStorage.getItem(name));
    },
    setSessionData(name, value) {
      if (value != null) sessionStorage.setItem(name, JSON.stringify(value));
      else sessionStorage.removeItem(name);
    },
    loadTranslations() {
      let vm = this;
      if (vm.getSessionData("language")) {
        vm.lang = vm.getSessionData("language");
      } else {
        vm.setSessionData("language", vm.lang);
      }

      const urlParams = new URLSearchParams(window.location.search);
      let param_lang = urlParams.get("lang");
      if (param_lang) {
        vm.lang = param_lang;
        vm.setSessionData("language", vm.lang);
      }

      console.log(vm.getSessionData("language"));
      console.log("vm.lang");

      console.log(this.lang);

      store.getItem("tranlations").then((params) => {
        if (params !== null) {
          vm.translations = JSON.parse(params);
          console.log("getTranscache");
          console.log(vm.translations);
          this.loadfinal = true;
        } else {
          axios
            .get(
              "https://devhub.rezcomm.com/hubapmydbauth/brandapi/devtrans.php?brandslug=" +
                vm.brand_slug
            )
            .then((response) => {
              console.log("getTranscache index");
              console.log(response);
              vm.translations = JSON.parse(response.request.response);
              store.setItem("tranlations", JSON.stringify(vm.translations));
              this.loadfinal = true;
            });
        }
      });
    },
    proceedtocheckuser() {
      if (!this.validateEmail(this.emailid)) {
        this.submitStatusCheckEmail = "ERROR";
        this.emailInvalid = true;
        this.emailValid = false;
        Swal.fire({
          title: "Alert",
          icon: "error",
          text: "Please enter email id to continue",
          confirmButtonText: "Close",
          showCloseButton: true,
        });
      } else {
        let emailEntered = this.emailid;
        this.submitStatusCheckEmail = "PENDING";
        this.emailInvalid = false;
        this.emailValid = true;
        let dataSearch = { brand_id: this.brand_slug, email_id: emailEntered };
        let secsa = {};
        if (this.brand_slug == "HER" || this.brand_slug == "PB1" || this.brand_slug == "FMM") {
          secsa = genkeys(
            encryptStorage.getItem("rez_dev_k").VUE_APP_VERIFYEMAIL_SID_FRE,
            encryptStorage.getItem("rez_dev_k").VUE_APP_VERIFYEMAIL_SKEY_FRA
          );
        } else if (this.brand_slug == "RDU" || this.brand_slug == "YHM" || this.brand_slug == "PV1" || this.brand_slug == "SV1" || this.brand_slug == "RSW" || this.brand_slug == "PIT" || this.brand_slug == "HVN" || this.brand_slug == "LAX") {
          secsa = genkeys(
            encryptStorage.getItem("rez_dev_k").VUE_APP_VERIFYEMAIL_SID_NVG,
            encryptStorage.getItem("rez_dev_k").VUE_APP_VERIFYEMAIL_SKEY_NVG
          );
        } else if (this.brand_slug == "NOC") {
          secsa = genkeys(
            encryptStorage.getItem("rez_dev_k").VUE_APP_VERIFYEMAIL_SID_IRE,
            encryptStorage.getItem("rez_dev_k").VUE_APP_VERIFYEMAIL_SKEY_IRE
          );          
        } else {
          secsa = genkeys(
            encryptStorage.getItem("rez_dev_k").VUE_APP_VERIFYEMAIL_SID_LDN,
            encryptStorage.getItem("rez_dev_k").VUE_APP_VERIFYEMAIL_SKEY_LDN
          );
        }
        let b1s = secsa.b1s;
        let v2s = secsa.v2s;
        let a1 = secsa.a1;
        let v1 = secsa.v1;
        let c2s = secsa.c2s;
        let uidgen = secsa.uidgen;
        let verapi = "";
        if (this.brand_slug == "HER" || this.brand_slug == "PB1" || this.brand_slug == "FMM") {
          verapi =
            encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_DEVAWS_FRE +
            "sso/frontend/v2/email/verify/exist";
        } else if (this.brand_slug == "RDU" || this.brand_slug == "YHM" || this.brand_slug == "PV1" || this.brand_slug == "SV1" || this.brand_slug == "RSW" || this.brand_slug == "PIT" || this.brand_slug == "HVN" || this.brand_slug == "LAX") {
          verapi =
            encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_LVAWS_NVG +
            "sso/frontend/v2/email/verify/exist";
          } else if (this.brand_slug == "NOC") {
            verapi =
            encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_LIVEAWS +
            "sso/frontend/email/verify/exist";          
        } else {
          verapi =
            encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_DEVAWS_LDN +
            "sso/frontend/v2/email/verify/exist";
        }
        axios
          .post(verapi, dataSearch, {
            headers: {
              b1: b1s,
              v2: v2s,
              c2s: c2s,
              a1: a1,
              v1: v1,
              c1: uidgen,
            },
          })
          .then(
            (response) => {
              let datasent = JSON.parse(response.request.response);
              console.log("datasent", datasent);
              if (typeof datasent.code.toString() === "undefined") {
                this.loginistrue = true;
                this.passistrue = false;
                this.password = "";
                this.userexists = true;
                this.submitStatusCheckEmail = "OK";
              } else {
                if (datasent.code.toString() == "37") {
                  this.loginistrue = true;
                  this.passistrue = false;
                  this.password = "";
                  this.userexists = false;
                  this.submitStatusCheckEmail = "OK";

                  if (!datasent.data.msg) {
                    console.log("datasent.data.msg", datasent.data.msg);
                    Swal.fire({
                      title: "Alert",
                      icon: "error",
                      text: "This account does not exist. Please contact Site Administrator",
                      confirmButtonText: "Close",
                      showCloseButton: true,
                    });
                  } else {
                    Swal.fire({
                      title: "Alert",
                      icon: "error",
                      text: this.getrans(datasent.data.msg),
                      confirmButtonText: "Close",
                      showCloseButton: true,
                    });
                  }
                } else if (datasent.code.toString() == "36") {
                  this.loginistrue = true;
                  this.passistrue = false;
                  this.password = "";
                  this.userexists = false;
                  this.submitStatusCheckEmail = "OK";

                  if (!datasent.data) {
                    console.log("datasent.data", datasent.data);
                    Swal.fire({
                      title: "Alert",
                      icon: "error",
                      text: "This account does not exist. Please contact Site Administrator",
                      confirmButtonText: "Close",
                      showCloseButton: true,
                    });
                  } else {
                    Swal.fire({
                      title: "Alert",
                      icon: "error",
                      text: this.getrans(datasent.data),
                      confirmButtonText: "Close",
                      showCloseButton: true,
                    });
                  }
                } else {
                  if (datasent.msg.login_type == "passwordless") {
                    this.passwordless = true;
                    let send_email = datasent.msg.send_email.toString();
                    let send_sms = datasent.msg.send_sms.toString();
                    let uuid = datasent.msg.uuid;
                    this.setLocalData("uuid", uuid);	
                    let callurl = "";
                    let calling = "1";
                    if (this.brand_slug == "NWI") {
                      if (send_email == "true" && send_sms == "false") {
                        calling = "1";
                      }
                      if (send_sms == "true" && send_email == "false") {
                        calling = "1";
                      }
                      if (send_sms == "true" && send_email == "true") {
                        callurl = "";
                        calling = "0";
                      }
                    } else {
                      calling = "1";				
                    }					
					if (calling == "1") {					
                    let datatogenotp = {
                      brand_id: this.brand_slug,
                      email_id: emailEntered,
                      language: this.lang.toUpperCase(),
                    };

                    let verapi = "";
                    let secotp = {};
                    if (this.brand_slug == "HER" || this.brand_slug == "PB1" || this.brand_slug == "FMM") {
                      verapi =
                        encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_DEVAWS_FRE +
                        "sso/frontend/v2/email/login/otp/send";
                      secotp = genkeys(
                        encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETID_FRA,
                        encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETKEY_FRA
                      );
                    } else if (
                      this.brand_slug == "RDU" || this.brand_slug == "SV1" ||
                      this.brand_slug == "YHM"  || this.brand_slug == "PV1" ||
                      this.brand_slug == "RSW" || this.brand_slug == "PIT" ||
                      this.brand_slug == "HVN" || this.brand_slug == "LAX"
                    ) {
                      verapi =
                        encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_LVAWS_NVG +
                        "sso/frontend/v2/email/login/otp/send";
                      secotp = genkeys(
                        encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETID_NVG,
                        encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETKEY_NVG
                      );
                    } else if (this.brand_slug == "NOC") {   
                      verapi =
                        encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_LIVEAWS +
                        "sso/frontend/email/login/otp/send";
                      secotp = genkeys(
                        encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETID_IRE,
                        encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETKEY_IRE
                      );                    
                    } else {
                      verapi =
                        encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_DEVAWS_LDN +
                        "sso/frontend/v2/email/login/otp/send";
                      secotp = genkeys(
                        encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETID_LDN,
                        encryptStorage.getItem("rez_dev_k").VUE_APP_OTP_SECRETKEY_LDN
                      );
                    }
                    let b1o = secotp.b1s;
                    let v2o = secotp.v2s;
                    let a1o = secotp.a1;
                    let v1o = secotp.v1;
                    let c2o = secotp.c2s;
                    let uidgeno = secotp.uidgen;

                    axios
                      .post(verapi, datatogenotp, {
                        headers: {
                          b1: b1o,
                          v2: v2o,
                          c2s: c2o,
                          a1: a1o,
                          v1: v1o,
                          c1: uidgeno,
                        },
                      })
                      .then((response) => {
                        console.log(response);
                        this.loginistrue = false;
                        this.passistrue = true;
                        this.password = "";
                        this.userexists = false;
                        this.submitStatusCheckEmail = "OK";
                        this.passwordWrong = false;
                        this.passwordCorrect = true;
                      });
                    } else {
                      this.userexists = false;
                      this.submitStatusCheckEmail = "OK";
                      this.passwordWrong = false;
                      this.passwordCorrect = true;
                      this.loginistrue = false;
                      this.callemailsms = true;
                    }											   
                  }
                }
              }
            },
            (error) => {
              console.log(error);
              Swal.fire({
                title: "Alert",
                icon: "error",
                text: "Some error occured. Contact Site Administrator",
                confirmButtonText: "Close",
                showCloseButton: true,
              });
              this.loginistrue = true;
              this.passistrue = false;
              this.password = "";
              this.userexists = false;
              this.submitStatusCheckEmail = "OK";
            }
          );
      }
    },
    cancellogin() {
      this.loginistrue = true;
      this.passistrue = false;
      this.password = "";
      this.userexists = false;
    },

    genHash(str) {
      var hash = 0,
        i,
        chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
      }
      return hash;
    },

    proceedtologinuser() {
      if (this.password == "") {
        this.submitStatusCheckPassword = "ERROR";
        this.passwordWrong = true;
        this.passwordCorrect = false;
      } else {
        let emailEntered = this.emailid;
        let passwordEntered = this.password;
        this.submitStatusCheckPassword = "PENDING";
        let device_id = this.genHash(emailEntered);
        let dataSearch = {};
        if (this.passwordless) {
          dataSearch = {
            brand_id: this.brand_slug,
            login_id: emailEntered,
            otp_code: passwordEntered,
            device_id: device_id,
          };
        } else {
          dataSearch = {
            brand_id: this.brand_slug,
            login_id: emailEntered,
            pwd: passwordEntered,
            device_id: device_id,
          };
        }
        let secsa = {};
        if (this.brand_slug == "HER" || this.brand_slug == "PB1" || this.brand_slug == "FMM") {
          secsa = genkeys(
            encryptStorage.getItem("rez_dev_k").VUE_APP_LOGIN_SECRETID_FRE,
            encryptStorage.getItem("rez_dev_k").VUE_APP_LOGIN_SECRETKEY_FRE
          );
        } else if (this.brand_slug == "RDU" || this.brand_slug == "YHM" || this.brand_slug == "PV1" || this.brand_slug == "SV1" || this.brand_slug == "RSW" || this.brand_slug == "PIT" || this.brand_slug == "HVN" || this.brand_slug == "LAX") {
          secsa = genkeys(
            encryptStorage.getItem("rez_dev_k").VUE_APP_LOGIN_SECRETID_NVG,
            encryptStorage.getItem("rez_dev_k").VUE_APP_LOGIN_SECRETKEY_NVG
          );
        } else if (this.brand_slug == "NOC") {       
          secsa = genkeys(
            encryptStorage.getItem("rez_dev_k").VUE_APP_LOGIN_SECRETID_IRE,
            encryptStorage.getItem("rez_dev_k").VUE_APP_LOGIN_SECRETKEY_IRE
          );            
        } else {
          secsa = genkeys(
            encryptStorage.getItem("rez_dev_k").VUE_APP_LOGIN_SECRETID_LDN,
            encryptStorage.getItem("rez_dev_k").VUE_APP_LOGIN_SECRETKEY_LDN
          );
        }
        let b1s = secsa.b1s;
        let v2s = secsa.v2s;
        let a1 = secsa.a1;
        let v1 = secsa.v1;
        let c2s = secsa.c2s;
        let uidgen = secsa.uidgen;
        let verapi = "";
        if (this.brand_slug == "HER" || this.brand_slug == "PB1" || this.brand_slug == "FMM") {
          verapi =
            encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_DEVAWS_FRE + "sso/frontend/v2/auth/login";
        } else if (this.brand_slug == "RDU" || this.brand_slug == "YHM"  || this.brand_slug == "PV1" || this.brand_slug == "SV1" || this.brand_slug == "RSW" || this.brand_slug == "PIT" || this.brand_slug == "HVN" || this.brand_slug == "LAX") {
          verapi =
            encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_LVAWS_NVG + "sso/frontend/v2/auth/login";
          } else if (this.brand_slug == "NOC") {
            verapi =
            encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_LIVEAWS + "sso/frontend/auth/login"; 
            
        } else {
          verapi =
            encryptStorage.getItem("rez_dev_k").VUE_APP_DOMAIN_DEVAWS_LDN + "sso/frontend/v2/auth/login";
        }
        axios
          .post(verapi, dataSearch, {
            headers: {
              b1: b1s,
              v2: v2s,
              c2s: c2s,
              a1: a1,
              v1: v1,
              c1: uidgen,
            },
          })
          .then(
            (response) => {
              //console.log(response.request.response);
              let datasent = JSON.parse(response.request.response);
              if (datasent.code == "1") {
                let accessToken = datasent.msg.accessToken;
                let refreshToken = datasent.msg.refreshToken;
                let uuid = datasent.msg.user_det.uuid.toString();
                this.$cookie.setCookie("guid_token", uuid, {
                  expire: 300 * 60,
                });
                this.setLocalData("accessToken", accessToken);
                this.setLocalData("refreshToken", refreshToken);
                this.setLocalData("emailEntered", emailEntered);
                this.gotoPage("/bookings.html");
              } else if (datasent.code == "26") {
                Swal.fire({
                  title: "Alert",
                  icon: "error",
                  text: "Some error occured. Contact Site Administrator",
                  confirmButtonText: "Close",
                  showCloseButton: true,
                });
                this.submitStatusCheckPassword = "ERROR";
                this.forgotPasswordOption = true;
              } else {
                Swal.fire({
                  title: "Alert",
                  icon: "error",
                  text: datasent.msg,
                  confirmButtonText: "Close",
                  showCloseButton: true,
                });
                this.submitStatusCheckPassword = "ERROR";
                this.forgotPasswordOption = true;
              }
            },
            (error) => {
              console.log(error);
              Swal.fire({
                title: "Alert",
                icon: "error",
                text: "Some error occured. Contact Site Administrator",
                confirmButtonText: "Close",
                showCloseButton: true,
              });
              this.passwordWrong = true;
              this.passwordCorrect = false;
              this.submitStatusCheckPassword = "ERROR";
            }
          );
      }
    },

    gotoPage(url) {
      window.location.href = url;
    },
    getLocalData(name) {
      return localStorage.getItem(name);
    },
    setLocalData(name, value) {
      if (value != null) localStorage.setItem(name, value);
      else localStorage.removeItem(name);
    },
    async get_rez_data_k()
    {
      if (typeof encryptStorage.getItem("rez_dev_k") === "undefined") 
      {
        let _this = this;
        await axios.get('https://devhub.rezcomm.com/hubapmydbauth/livedata/mmb.php')
        .then(response => {

        const key = 'XwB?au@qy*^y!r6R&wV8fVuSXmGKf3Sn';
        const encryptedData = response.data;
        let dec = _this.decrypt_custom(key, encryptedData);
        encryptStorage.setItem("rez_dev_k",dec);        
        })
        .then(() => {
_this.brand_slug = _this.getSlug();
_this.lang = "EN";

if (_this.brand_slug === "") {
  _this.setSlug().then((response) => {
	console.log(_this.getCurrency());
  _this.setBranding(response).then((response) => {
  _this.brand_slug = _this.getSlug();
  if (_this.brand_slug != "") {encryptStorage.setItem('rez_dev_k_brand',_this.brand_slug);}
  _this.branding_data = response;
  _this.loadTranslations();
  _this.setStyles(_this.branding_data.brand_product_styles);
  console.log("bb", _this.branding_data);
  _this.setMenu(_this.branding_data);
	});
  });
} else {
  _this.branding_data = _this.getBranding();
  if (_this.brand_slug != "") {encryptStorage.setItem('rez_dev_k_brand',_this.brand_slug);}
  if (JSON.stringify(_this.branding_data) === JSON.stringify({})) {
  _this.setBranding(_this.brand_slug).then((response) => {
  _this.branding_data = response;
  _this.loadTranslations();
  console.log("dd", _this.branding_data);
  _this.setStyles(_this.branding_data.brand_product_styles);
  _this.setMenu(_this.branding_data);
	});
  } else {
	console.log("cc", _this.branding_data);
	_this.loadTranslations();
	_this.setStyles(_this.branding_data.brand_product_styles);
	_this.setMenu(_this.branding_data);
  }
}

        }
        )
        .catch(error => {
        console.error(error);
        });
        }
        else
        {
	this.brand_slug = this.getSlug();
    this.lang = "EN";

    if (this.brand_slug === "") {
      this.setSlug().then((response) => {
        console.log(this.getCurrency());
        this.setBranding(response).then((response) => {
          this.brand_slug = this.getSlug();
          if (this.brand_slug != "") {encryptStorage.setItem('rez_dev_k_brand',this.brand_slug);}
          this.branding_data = response;
          this.loadTranslations();
          this.setStyles(this.branding_data.brand_product_styles);
          console.log("bb", this.branding_data);
          this.setMenu(this.branding_data);
        });
      });
    } else {
      this.branding_data = this.getBranding();
      if (this.brand_slug != "") {encryptStorage.setItem('rez_dev_k_brand',this.brand_slug);}
      if (JSON.stringify(this.branding_data) === JSON.stringify({})) {
        this.setBranding(this.brand_slug).then((response) => {

          this.branding_data = response;
          this.loadTranslations();
          console.log("dd", this.branding_data);
          this.setStyles(this.branding_data.brand_product_styles);
          this.setMenu(this.branding_data);
        });
      } else {
        console.log("cc", this.branding_data);
        this.loadTranslations();
        this.setStyles(this.branding_data.brand_product_styles);
        this.setMenu(this.branding_data);
      }
    }
        }
    },
    decrypt_custom(key, data) {
      
      const decodedData = Buffer.from(data, 'base64').toString('utf8');
        const parts = decodedData.split('::');
    
        // Extract IV
        key = Buffer.from(parts[0]);
        let iv = Buffer.from(parts[2], 'hex');
    
        // Extract encrypted data
        const encryptedData = Buffer.from(parts[1], 'base64');
    
        // Create decipher object with correct IV and key
        const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);
    
        // Update decryption with encrypted data
        let decrypted = decipher.update(encryptedData);
    
        // Finalize the decryption
        decrypted = Buffer.concat([decrypted, decipher.final()]);
    
        return decrypted.toString('utf8');
},    
  },
  mounted() {
    localStorage.clear(); 
    sessionStorage.clear();
    this.brandSlug = this.getSlug();
    this.get_rez_data_k();
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/normalise.scss";
@import "@/assets/sass/bodyStyle.scss";
@import "@/assets/sass/calenderOverride.scss";
@import "@/assets/sass/swalOverride.scss";
</style>

<style scoped lang="scss">
@import "@/assets/sass/baseTimerModel.scss";
@import "@/assets/sass/formStyles.scss";

$comfirm-button: #ea3e76;

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.top-panel {
  background: var(--header_banner_background, #1a2a40);
  color: var(--header_banner_textcolor, #fff);
  .top-panel-inner {
    max-width: 1270px;
    text-align: left;
    margin: 0 auto;
    padding: 1rem;
    h1 {
      font-weight: lighter;
    }
  }
}

$basketWidth: "420px";
.page-container {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  height: auto;
  position: relative;

  .page-time-notice {
    text-align: left;
    display: flex;
    padding: 10px 0;
    p {
      max-width: calc(100% - 120px);
      padding-right: 30px;
      line-height: 1.4;
      font-size: 18px;
    }
    .base-timer-wrapper {
      width: 90px;
      height: 90px;
      margin-top: 15px;
    }
  }

  .big-bold {
    font-size: 20px;
    font-weight: bold;
  }

  .page-left {
    margin: 0 15px;
    margin-bottom: 40px;
    @media screen and (min-width: 958px) {
      width: calc((100% - #{$basketWidth}) - 45px);
      margin-left: 15px;
      margin-right: 30px;
    }
  }
  .page-right {
    width: 0px;
    @media screen and (min-width: 958px) {
      display: block;
      width: #{$basketWidth};
      margin-right: 8px;
    }
  }
}

// end page styles

.confirm-button {
  margin-top: 30px;
  max-width: 480px;
  button {
    min-height: 70px;
    width: 100%;
    height: 100%;
    background: $comfirm-button;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border: 0px solid #fff;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      background: darken($comfirm-button, 10%);
    }
  }
}

.title-area {
  //width: calc(100% / 3 * 2);
  //width: #{'calc(100% - 412px)'};
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 10px 30px 10px 10px;
  box-sizing: border-box;
  * {
    margin: 0 !important;
    padding: 0 !important;
    @media screen and (max-width: 500px) {
      display: block;
      text-align: left;
      float: none !important;
      margin-top: 16px !important;
    }
  }
  @media screen and (max-width: 959px) {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
  }
  @media screen and (max-width: 959px) {
    padding: 10px 0px;
  }
}

.title-area,
.permissions {
  h3 {
    color: #364d5b;
  }
}

.find-address-btn {
  padding: 0;
  height: 48px;
  margin-top: 10px;
  button {
    width: 100%;
    height: 100%;
    background: #006ab3;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border: 0px solid #fff;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
      background: darken(#005b9a, 10%);
    }
  }
}

.postcode-message {
  padding: 20px 10px;
  color: #666666;
  line-height: 1.4;
}

.block-wrap-addresses {
  max-height: 300px;
  overflow-y: scroll;
  overflow-y: auto;
  margin-bottom: 40px;
  display: block;
  div {
    padding: 5px 10px;
  }
}

.p-inputtext.p-component {
  height: 57px;
}

.adult_child_fields {
  border: 1px solid #ddd;
  padding: 0 15px;
  margin-bottom: 30px;
}

.dob-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cal-wrapper {
  padding-top: 25px;
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 3px;
  position: relative;
  .close-cal-btn {
    font-size: 18px;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px;
    cursor: pointer;
    z-index: 9999;
  }
}

.model-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  .model-inner {
    margin: 10px;
    padding-top: 25px;
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 3px;
    position: relative;
    .close-model-btn {
      font-size: 18px;
      position: absolute;
      right: 0px;
      top: 0px;
      padding: 10px;
      cursor: pointer;
      z-index: 9999;
    }
    .model-content {
      padding: 15px;
      p {
        color: #666666;
        line-height: 1.4;
      }
      .block {
        width: 100%;
        padding-right: 0px;
      }
      .model-content-btn {
        margin: 0 0 15px;
        width: 100% !important;
      }
    }
  }
}

.forgot-inner {
  max-width: 400px;
}

.adults-fields {
  width: calc(100% / 3);
  @media screen and (max-width: 1280px) {
    width: calc(100% / 2);
  }
}

.child-fields {
  width: calc(100% / 4);
  @media screen and (max-width: 1280px) {
    width: calc(100% / 2);
  }
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.block-wrap-btn button {
  font-weight: bold;
}

.proceed-btn {
  background: var(--mmb_login_button_background, #0a3b74);
  color: var(--mmb_login_button_font_color, #fff);
  font-size: var(--mmb_login_button_font_size, inherit);
  font-family: var(--mmb_login_button_font_family, inherit);
  font-weight: var(--mmb_login_button_font_weight, inherit);
  border: var(--mmb_login_button_border, none);
  border-radius: var(--mmb_login_button_border_radius, 0);
}
</style>

<style>
.internal.internal.internal {
  display: none;
}
</style>
